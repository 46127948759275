<template>
  <b-card>
    <validation-observer ref="formStudent" v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(save)">
        <b-row>
          <b-col cols="12" class="mb-2"><h3>Data Dosen</h3></b-col>

          <b-col cols="12">
            <b-form-group label="NIP" label-for="h-nip" label-cols-md="2">
              <b-form-input
                id="h-nip"
                placeholder="Nomor Induk Pegawai"
                :disabled="true"
                v-model="model.nip"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Nama Dosen"
              label-for="h-name"
              label-cols-md="2"
            >
              <validation-provider
                #default="{ errors }"
                name="Nama Dosen"
                rules="required"
              >
                <b-form-input
                  id="h-name"
                  placeholder="Nama Dosen"
                  :disabled="config.action === 'show'"
                  :state="errors.length > 0 ? false : null"
                  v-model="model.name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Mata Kuliah Diampuh"
              label-for="h-name"
              label-cols-md="2"
            >
              <b-form-tags
                v-model="model.courses"
                input-id="tags-separators"
                separator=" ,;"
                placeholder=" "
                class="mb-2"
                :disabled="true"
              />
            </b-form-group>
          </b-col>

          <b-col class="text-right">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mr-1"
              @click="$router.go(-1)"
            >
              Kembali
            </b-button>
            <b-button
              v-if="config.action == 'edit'"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
            >
              Simpan
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardText,
  BFormTags,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormTags,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      config: {
        action: this.$route.meta.action,
        api: "lecturers",
        redirect: "lecturer",
      },
      model: {},
    };
  },
  created() {
    const _ = this;
    _.loadData();
  },
  methods: {
    loadData() {
      const _ = this;
      _.$axios.get(`${_.config.api}/${_.$route.params.id}`).then((res) => {
        const consume = res.data;
        _.model = consume;
        _.model.courses = consume.courses.map((value) => value.name);
      });
    },
    save() {
      const _ = this;
      _.$axios
        .patch(`${_.config.api}/${_.$route.params.id}`, _.model)
        .then((res) => {
          _.$router.push({
            name: _.config.redirect,
            params: {
              variant: "success",
              text: "Data Berhasil di Ubah",
            },
          });
        });
    },
  },
};
</script>

<style></style>
